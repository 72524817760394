<app-base-page [breadCrumb]="breadCrumb">
  <ng-container page-header>
    <h4>Example with card container enabled (default/omit is true)</h4>
  </ng-container>
  any content written directly is inside the body
  <p>body example</p>
  <ng-container page-footer>
    <h6>footer content (below body)</h6>
  </ng-container>
</app-base-page>
<app-base-page [breadCrumb]="breadCrumb" [cardContainer]="false">
  <ng-container page-header>
    <h4>Example with card container disabled</h4>
  </ng-container>
  any content written directly is inside the body
  <p>body example</p>
  <ng-container page-footer>
    <h6>footer content (below body)</h6>
  </ng-container>
</app-base-page>
