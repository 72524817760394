import { Component } from '@angular/core';
import { SignOut } from '@core/state';
import { Store } from '@ngxs/store';
import { AppComponent } from './app.component';
import { ConfigService } from './app.config.service';
import { AppMainComponent } from './app.main.component';

@Component({
  selector: 'app-topbar',
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-left">
        @if (appMain.isOverlay() || appMain.isMobile()) {
          <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
          </a>
        }

        <a href="#" class="logo">
          <!-- 'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'light' : 'dark') + '.png' -->
          <img [src]="app.colorScheme === 'light' ? configService.logoImgLight : configService.logoImgDark" />
        </a>

        <a href="#">
          <!-- 'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'light' : 'dark') + '.png' -->
          <img
            [src]="app.colorScheme === 'light' ? configService.appNameImgLight : configService.appNameImgDark"
            class="app-name"
          />
        </a>
      </div>

      <app-menu></app-menu>

      <div class="layout-topbar-right">
        <ul class="layout-topbar-right-items">
          <li #profile class="profile-item" [ngClass]="{ 'active-topmenuitem': appMain.activeTopbarItem === profile }">
            <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
              <img class="border-circle" src="assets/layout/images/profile-image.png" />
            </a>

            <ul class="fadeInDown" style="z-index:99999999;">
              <!-- <li role="menuitem">
                  <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                    <i class="pi pi-fw pi-user"></i>
                    <span>Profile</span>
                  </a>
                </li>
                <li role="menuitem">
                  <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                    <i class="pi pi-fw pi-cog"></i>
                    <span>Settings</span>
                  </a>
                </li> -->
              <li role="menuitem">
                <a (click)="appMain.goToProfile()">
                  <i class="pi pi-user pi-fw"></i>
                  <span>Profile</span>
                </a>
              </li>
              <li role="menuitem">
                <a (click)="appMain.goToPreferences()">
                  <i class="pi pi-user-edit pi-fw"></i>
                  <span>Preferences</span>
                </a>
              </li>
              <li role="menuitem">
                <a (click)="onConfigButtonClick($event)">
                  <i class="pi pi-cog pi-fw"></i>
                  <span>Theme Config</span>
                </a>
              </li>
              <li role="menuitem">
                <a href="#" (click)="signOut()">
                  <i class="pi pi-fw pi-sign-out"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li>
            <a href="#">
              <i class="topbar-icon pi pi-fw pi-bell"></i>
              <span class="topbar-badge">2</span>
              <span class="topbar-item-name">Notifications</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="topbar-icon pi pi-fw pi-comment"></i>
              <span class="topbar-badge">5</span>
              <span class="topbar-item-name">Messages</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  `,
})
export class AppTopbarComponent {
  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private store: Store,
    public configService: ConfigService
  ) {}
  signOut(): void {
    this.store.dispatch(new SignOut());
  }
  onConfigButtonClick(event) {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }
}
