import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { ConfigService } from './app.config.service';

@Component({
  selector: 'app-footer',
  template: `
    <div class="layout-footer">
      <div class="footer-logo-container">
        <img
          id="footer-logo"
          [src]="'assets/layout/images/logo-' + (app.colorScheme === 'light' ? 'light' : 'dark') + '.png'"
          alt="Sightflare-layout"
        />
        <!-- <img id="footer-logo"   [src]="(app.colorScheme === 'light' ? configService.logoImgDark : configService.logoImgLight)" alt="Sightflare-layout"/> -->
        <img
          [src]="'assets/layout/images/appname-' + (app.colorScheme === 'light' ? 'light' : 'dark') + '.png'"
          class="app-name"
        />
        <!-- <img  [src]="(app.colorScheme === 'light' ? configService.appNameImgDark : configService.appNameImgLight)" class="app-name"/> -->
        <!-- <span class="app-name">Sightflare</span> -->
      </div>
      <span class="copyright">{{ configService.tenantTitle || 'Sightflare' }} - {{ year }}</span>
    </div>
  `,
})
export class AppFooterComponent {
  year = new Date().getFullYear();
  constructor(
    public app: AppComponent,
    public configService: ConfigService
  ) {}
}
