<app-base-page [breadCrumb]="breadCrumb">
  <ng-container page-header>
    <h4>Example Form usage with all app input fields</h4>
  </ng-container>
  <app-form-example
    (formChanges)="formChanges($event)"
    [formData]="itemFormData"
    (formSubmit)="onSubmit($event)"
  ></app-form-example>
</app-base-page>
