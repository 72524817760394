import { Component } from '@angular/core';
import { BasePage } from 'app/shared/classes/view/BasePage';

@Component({
  templateUrl: './emptydemo.component.html',
})
export class EmptyDemoComponent extends BasePage {
  constructor() {
    super();
    this.SetPageValues({
      breadCrumb: { items: [{ label: 'Pages' }, { label: 'Empty Page', routerLink: ['/pages/empty'] }] },
    });
    //@NOTE: can and should use subsink to automatically remove subs on destroy
    /* Example (the class has more examples but rewritten here to emphasize the emportance of usage )
     *  this.subs.sink = subscribtion
     *  ------------- or -------------
     *  this.subs.add(subscribtion[])
     */
  }
}
