import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { AppPermissions } from '@shared/classes';
import { GlobalSearchPopupComponent } from '@shared/components/components/global-search/global-search-popup/global-search-popup.component';
import { AppDialogService } from '@shared/services';
import { SocketDataService } from '@shared/services/socket-data.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { AppMainComponent } from './app.main.component';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {
  subscription: Subscription;
  permissionSubscription: Subscription;
  readNotificationsPermission = AppPermissions.ReadMyNotification;
  items: MenuItem[];

  home: MenuItem;

  search: string;
  dashboardDropdown = new FormControl('main');
  dashboardList = [];
  defaultDashboardlist = [
    { value: 'main', label: 'Main' },
    { value: 'policies', label: 'Policies', permissions: [AppPermissions.ReadPolicy] },
    {
      value: 'controls',
      label: 'Controls',
      permissions: [AppPermissions.ReadControl, AppPermissions.ReadControlGroup],
    },

    // , { value: 'entities', label: 'Entities' }
  ];
  constructor(
    public breadcrumbService: AppBreadcrumbService,
    public appMain: AppMainComponent,
    public viewModeService: ViewModeService,
    public socketService: SocketDataService,
    private permissionService: NgxPermissionsService,
    private router: Router,
    private appDialogService: AppDialogService,
    private localeService: LocaleService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
      // this.fixDropdownSelection();
    });

    // this.routeSubscription = router.events.subscribe((val) => { this.fixDropdownSelection() });

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  fixDropdownSelection() {
    const segments = this.router.url.split('/');
    const lastSegment = segments[segments.length - 1];
    if (lastSegment && this.dashboardList.map((x) => x.value).includes(lastSegment)) {
      this.dashboardDropdown.setValue(lastSegment);
    }
  }

  checkDropDownPermissions(perms: NgxPermissionsObject) {
    let newList = this.defaultDashboardlist;
    newList = newList.filter((dashboard) => {
      if (dashboard.permissions) {
        let hasPerm: boolean = false;
        for (let i = 0; i < dashboard.permissions.length; i++) {
          const permission = dashboard.permissions[i];
          if (perms[permission]) {
            hasPerm = true;
            break;
          }
        }
        return hasPerm;
      }
      return true;
    });

    return newList;
  }

  ngOnInit(): void {
    this.permissionSubscription = this.permissionService.permissions$.subscribe((res) => {
      this.dashboardList = this.checkDropDownPermissions(res);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.permissionSubscription) {
      this.permissionSubscription.unsubscribe();
    }
  }
  showNotifications() {}
  onChanges(dashboard) {
    this.router.navigateByUrl('/dashboards/' + dashboard);
  }

  showGlobalSearchPopup() {
    this.appDialogService.showDialog(
      GlobalSearchPopupComponent,
      this.localeService.translate('general.cards.globalSearch.globalSearch'),
      (e) => {},
      {
        data: {},
      }
    );
  }
}
