import { Component } from '@angular/core';
import { BasePage } from 'app/shared/classes/view/BasePage';
import { ToastService } from 'app/shared/services/toast.service';

@Component({
  templateUrl: './formdemo.component.html',
})
export class FormDemoComponent extends BasePage {
  itemFormData: any = {}; //Mock Data example (useful for patching for with api req data)
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes

  constructor(private toastService: ToastService) {
    super();
    this.SetPageValues({
      breadCrumb: {
        items: [{ label: 'Pages' }, { label: 'Form Page', routerLink: ['/pages/form'] }],
      },
    });
    this.fetchFormData();
  }

  onSubmit(data) {
    this.toastService.info('Form Content', JSON.stringify(data));
  }

  formChanges(data) {
    this.realTimeFormData = data;
  }

  fetchFormData() {
    setTimeout(() => {
      //MOCK EXAMPLE FOR REALTIME UPDATE (ex: after api req)
      this.itemFormData = {
        text: '',
        email: '',
        checkbox: true,
        slider: true,
        radioGroup: 'option 2',
        textArea: null,
        textEditor: null,
        number: null,
        date: null,
        dropdown: null,
        multiDropdown: null,
      };
      this.realTimeFormData = { ...this.itemFormData };
    }, 4000);
  }
}
