import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DynamicFormExampleComponent } from '@shared/components/examples/dynamic-form-example/dynamic-form-example.component';
import { TableExampleComponent } from '@shared/components/examples/table-example/table-example.component';
import { AppPathRedirectComponent } from '@shared/pages/app-path-redirect/app-path-redirect.component';
import { GlobalSearchPageComponent } from '@shared/pages/global-search-page/global-search-page.component';
import { AppMainComponent } from './app.main.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { EmptyDemoComponent } from './pages/emptydemo.component';
import { FormDemoComponent } from './pages/formdemo.component';
// dont reorder these (if these imports goes above other imports build order will break due to mismatch order of building services)
import { UserPreferenceItemComponent } from '@shared/components/components/user-preference/user-preference-item/user-preference-item.component';
import { AppWelcomeComponent } from './pages/app.welcome.component';
@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          canActivate: [AuthGuard],
          children: [
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            // { path: "", component: DashboardComponent },
            // { path: "dashboard", component: DashboardComponent },
            { path: 'pages/empty', component: EmptyDemoComponent },
            { path: 'pages/form', component: FormDemoComponent },
            { path: 'pages/dynamic-form', component: DynamicFormExampleComponent },
            { path: 'pages/table', component: TableExampleComponent },
            { path: 'user-preferences/:id', component: UserPreferenceItemComponent },
            { path: 'global-search', component: GlobalSearchPageComponent },
            {
              path: 'question-management',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/questions/questions.module').then((m) => m.QuestionsModule),
            },
            {
              path: 'entity',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/entity-module/entities.module').then((m) => m.EntitiesModule),
            },
            {
              path: 'risk',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/risk/risk.module').then((m) => m.RiskModule),
            },
            {
              path: 'redirect',
              canActivate: [AuthGuard],
              component: AppPathRedirectComponent,
            },
            {
              path: 'acknowledgment-management',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/acknowledgement-module/acknowledgement-module.module').then(
                  (m) => m.AcknowledgementModuleModule
                ),
            },
            {
              path: 'framework',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/org-framework/org-framework.module').then((m) => m.OrgFrameworkModule),
            },
            // { path: 'authority', canActivate: [AuthGuard], loadChildren: () => import('./modules/authority-module/authority-module.module').then(m => m.AuthorityModuleModule) },
            {
              path: 'user-management',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/users/user-module.module').then((m) => m.UserModuleModule),
            },
            {
              path: 'transparency-management',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/transparency-management/transparency-management.module').then(
                  (m) => m.TransparencyManagementModule
                ),
            },
            {
              path: 'issue-management',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/issues-module/issues-module.module').then((m) => m.IssuesModuleModule),
            },

            {
              path: 'file-manager',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/file-manager-module/file-manager.module').then((m) => m.FileManagerModule),
            },
            {
              path: 'dashboard',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/dashboards-module/dashboards-module.module').then((m) => m.DashboardsModuleModule),
            },
            {
              path: 'activity-feed',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/activity-feed/activity-feed.module').then((m) => m.ActivityFeedModule),
            },
            {
              path: 'audit',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/audit-module/audit-module.module').then((m) => m.AuditModuleModule),
            },
            {
              path: 'data-management',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/data-structure-module/data-structure-module.module').then(
                  (m) => m.DataStructureModuleModule
                ),
            },
            {
              path: 'relation',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/relation-module/relation-module.module').then((m) => m.RelationModuleModule),
            },
            {
              path: 'global-package',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/global-package-module/global-package.module').then((m) => m.GlobalPackageModule),
            },
            {
              path: 'performance',
              canActivate: [AuthGuard],
              loadChildren: () =>
                import('./modules/performance-module/performance.module').then((m) => m.PerformanceModule),
            },
          ],
        },
        { path: 'welcome', component: AppWelcomeComponent },
        { path: 'error', component: AppErrorComponent },
        { path: 'access', component: AppAccessdeniedComponent },
        { path: 'notfound', component: AppNotfoundComponent },
        { path: 'login', component: AppLoginComponent },
        {
          path: 'kanban',
          data: { breadcrumb: 'Kanban' },
          loadChildren: () => import('./shared/modules/kanban/kanban.app.module').then((m) => m.KanbanAppModule),
        },
        { path: '**', redirectTo: '/notfound' },
      ],
      { scrollPositionRestoration: 'enabled' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
