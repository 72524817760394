import { Component, OnInit } from '@angular/core';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import {
  BadgeColor,
  Control,
  DataTypeEnum,
  IColumn,
  ITableLoadEvent,
  Issue,
  RiskItem,
  RiskMethodology,
  Task,
} from '@shared/classes';
import {
  CompactType,
  DisplayGrid,
  Draggable,
  GridType,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  PushDirections,
  Resizable,
} from 'angular-gridster2';
import { AppMainComponent } from 'app/app.main.component';
import { TaskDataService } from 'app/modules/activity-feed/services/data/task-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { IssuesDataService } from 'app/modules/issues-module/services/data/issues-data.service';
import { ControlDataService } from 'app/modules/org-framework/service/data/ControlDataService';
import { RiskMethodologyComponent } from 'app/modules/risk/risk-methodology/component/RiskMethodology/RiskMethodology.component';
import { RiskItemDataService } from 'app/modules/risk/services/data/risk-item-data.service';
import { RiskMethodologyDataService } from 'app/modules/risk/services/data/risk-methodology-data.service';
import { BasePage } from 'app/shared/classes/view/BasePage';
import { find } from 'lodash-es';
import { forkJoin, takeUntil } from 'rxjs';
interface Safe extends GridsterConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}
@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends BasePage implements OnInit {
  badgeColors = BadgeColor;
  options: Safe;
  dashboard: Array<GridsterItem>;
  riskMethod = RiskMethodologyComponent;
  plugin = {
    id: 'centerText',
    beforeDraw: function (chart) {
      if (chart.config.options?.elements?.center) {
        // Get ctx from string
        var ctx = chart.ctx;
        const outerRadius = chart._metasets?.[0]?.data?.[0]?.outerRadius;
        const innerRadius = chart._metasets?.[chart?._metasets?.length - 1]?.data?.[0]?.innerRadius;

        // Get options from the center object in options
        var centerConfig = chart.config.options.elements.center;
        var fontStyle = centerConfig.fontStyle || 'Arial';
        var txt = centerConfig.text;
        var color = centerConfig.color || '#000';
        var maxFontSize = centerConfig.maxFontSize || 75;
        var sidePadding = centerConfig.sidePadding || 20;
        var sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);
        // Start with a base font of 30px
        ctx.font = '30px ' + fontStyle;

        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        var stringWidth = ctx.measureText(txt).width;
        var elementWidth = innerRadius * 2 - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        var widthRatio = elementWidth / stringWidth;
        var newFontSize = Math.floor(30 * widthRatio);
        var elementHeight = innerRadius * 2;

        // Pick a new font size so it will not be larger than the height of label.
        var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
        var minFontSize = centerConfig.minFontSize;
        var lineHeight = centerConfig.lineHeight || 25;
        var wrapText = false;

        if (minFontSize === undefined) {
          minFontSize = 20;
        }

        if (minFontSize && (fontSizeToUse < minFontSize || !fontSizeToUse)) {
          fontSizeToUse = minFontSize;
          wrapText = true;
        }

        // Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = fontSizeToUse + 'px ' + fontStyle;
        ctx.fillStyle = color;

        if (!wrapText) {
          ctx.fillText(txt, centerX, centerY);
          return;
        }

        var words = txt.split(' ');
        var line = '';
        var lines = [];

        // Break words up into multiple lines if necessary
        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = ctx.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > elementWidth && n > 0) {
            lines.push(line);
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        // Move the center up depending on line height and number of lines
        centerY -= (lines.length / 2) * lineHeight;

        for (var n = 0; n < lines.length; n++) {
          ctx.fillText(lines[n], centerX, centerY);
          centerY += lineHeight;
        }
        //Draw text in center
        ctx.fillText(line, centerX, centerY);
      }
    },
  };
  constructor(
    private riskMethService: RiskMethodologyDataService,
    private riskItemService: RiskItemDataService,
    private taskService: TaskDataService,
    private issueService: IssuesDataService,
    private controlService: ControlDataService,
    private objectivesDataService: ObjectivesDataService,
    private eventsDataService: EventsDataService,
    private appMain: AppMainComponent,
    private jwtService: JWTTokenService
  ) {
    super();
    setTimeout(() => {
      this.SetPageValues({ breadCrumb: { items: [{ label: 'Dashboard', routerLink: ['/'] }] } });
    }, 1000);
    //@NOTE: can and should use subsink to automatically remove subs on destroy
    /* Example (the class has more examples but rewritten here to emphasize the emportance of usage )
     *  this.subs.sink = subscribtion
     *  ------------- or -------------
     *  this.subs.add(subscribtion[])
     */
    this.getData();
  }
  itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    // console.info('itemChanged', item, itemComponent);

    localStorage.setItem('main-dashboard', JSON.stringify(this.dashboard));
  }
  ngOnInit(): void {
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.CompactLeftAndUp,
      itemChangeCallback: this.itemChange.bind(this),
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      useBodyForBreakpoint: true,
      minCols: 1,
      maxCols: 12,
      minRows: 1,
      maxRows: 100,
      maxItemCols: 12,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
    let placeHolderControls = { CORRECTIVE: 50, PREVENTIVE: 100, DETECTIVE: 90 };
    let placeHolderIssues = { CRITICAL: 5, HIGH: 10, MODERATE: 15, LOW: 4, INFO: 1 };
    let placeHolderRisks = { 'Acceptable Risks': 5, 'High Risks': 10 };
    const root = document.documentElement;
    const style = getComputedStyle(root);
    this.dashboard = this.changeDashboardItems([
      {
        id: 1,
        cols: 4,
        rows: 1.25,
        y: 0,
        x: 0,
        dragEnabled: true,
        resizeEnabled: true,
        wid5: true,
      },
      {
        id: 2,
        cols: 4,
        rows: 1.25,
        y: 0,
        x: 4,
        dragEnabled: true,
        resizeEnabled: true,
        wid2: true,
      },
      {
        id: 3,
        cols: 4,
        rows: 1.25,
        y: 0,
        x: 8,
        dragEnabled: true,
        resizeEnabled: true,
        wid1: true,
      },
      {
        id: 4,
        cols: 12,
        rows: 3,
        y: 1.25,
        x: 0,
        dragEnabled: true,
        resizeEnabled: true,
        riskMethod: true,
      },
      {
        id: 5,
        cols: 8,
        rows: 4,
        y: 4.25,
        x: 0,
        dragEnabled: true,
        resizeEnabled: true,
        hasContent: true,
        table1: true,
      },
      {
        id: 6,
        cols: 8,
        rows: 4,
        y: 8.25,
        x: 6,
        dragEnabled: true,
        resizeEnabled: true,
        hasContent: true,
        table2: true,
      },
      {
        id: 7,
        cols: 8,
        rows: 4,
        y: 12.25,
        x: 0,
        dragEnabled: true,
        resizeEnabled: true,
        hasContent: true,
        chart1: true,
        chartData: {
          labels: ['Low', 'Mid', 'High'],
          datasets: [
            {
              label: 'Risk Values',
              backgroundColor: ['green', 'yellow', 'red'],
              data: [5, 2, 3],
            },
          ],
        },
        chartOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#868C9B',
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: '#ebedef',
              },
              grid: {
                color: 'rgba(255,255,255,0.2)',
              },
            },
            y: {
              ticks: {
                color: '#ebedef',
              },
              grid: {
                color: 'rgba(255,255,255,0.2)',
              },
            },
          },
        },
      },
      {
        id: 8,
        cols: 4,
        rows: 4.5,
        y: 4.25,
        x: 8,
        dragEnabled: true,
        resizeEnabled: true,
        chart2: true,
        listData: placeHolderControls,
        chartData: {
          labels: Object.keys(placeHolderControls),
          datasets: [
            {
              data: Object.keys(placeHolderControls).map((x) => placeHolderControls[x]),
              backgroundColor: Object.keys(placeHolderControls).map((x) =>
                style.getPropertyValue(`--${this.badgeColors[x]}-500`)
              ),
              borderColor: 'transparent',
              fill: true,
            },
          ],
        },
        chartOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#868C9B',
              },
            },
          },
        },
      },
      {
        id: 9,
        cols: 4,
        rows: 5.25,
        y: 8.75,
        x: 8,
        dragEnabled: true,
        resizeEnabled: true,
        chart3: true,
        listData: placeHolderIssues,
        chartData: {
          labels: Object.keys(placeHolderIssues),
          datasets: [
            {
              data: Object.keys(placeHolderIssues).map((x) => placeHolderIssues[x]),
              backgroundColor: Object.keys(placeHolderIssues).map((x) =>
                style.getPropertyValue(`--${this.badgeColors[x]}-500`)
              ),
              borderColor: 'transparent',
              fill: true,
            },
          ],
        },
        chartOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#868C9B',
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + '%';
                return percentage;
              },
              color: '#fff',
            },
          },
        },
      },
      {
        id: 10,
        cols: 4,
        rows: 4.5,
        y: 14,
        x: 8,
        dragEnabled: true,
        resizeEnabled: true,
        chart4: true,
        listData: placeHolderRisks,
        chartData: {
          labels: Object.keys(placeHolderRisks),
          datasets: [
            {
              data: Object.keys(placeHolderRisks).map((x) => placeHolderRisks[x]),
              backgroundColor: Object.keys(placeHolderRisks).map((x) =>
                style.getPropertyValue(`--${this.badgeColors[x]}-500`)
              ),
              borderColor: 'transparent',
              fill: true,
            },
          ],
        },
        chartOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#868C9B',
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + '%';
                return percentage;
              },
              color: '#fff',
            },
          },
        },
      },
    ]);
    this.appMain.sideBarState$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this?.options?.api?.resize();
    });
  }
  getData() {
    forkJoin({
      activeMeth: this.riskMethService.fetchActiveRiskMethodology(),
      riskItems: this.riskItemService.search(
        { all: true },
        { projectionFields: null, filters: null },
        { showLoading: false, showMsg: false }
      ),
      issues: this.issueService.search(
        { all: true },
        { projectionFields: null, filters: null },
        { showLoading: false, showMsg: false }
      ),
      tasks: this.taskService.search(
        { all: true },
        { projectionFields: null, filters: null },
        { showLoading: false, showMsg: false }
      ),
      controls: this.controlService.search(
        { all: true },
        { projectionFields: null, filters: null },
        { showLoading: false, showMsg: false }
      ),
    }).subscribe((res) => {
      let issueData = this.parseIssuesData(res.issues as any as Issue[]);
      let controlData = this.parseControlsData(res.controls as any as Control[]);
      let riskData = this.parseRisksData(res.riskItems as any as RiskItem[], res.activeMeth.data);

      let taskData = this.parseTasksData(res.tasks as any as Task[]);

      let belowAmount = (res?.riskItems as any as RiskItem[])?.filter(
        (x) => x?.residualRisk < res?.activeMeth?.data?.acceptableRisk
      )?.length;
      let placeHolderRisks = {
        'Acceptable Risks': belowAmount,
        'High Risks': (res?.riskItems as any as RiskItem[])?.length - belowAmount,
      };
      const root = document.documentElement;
      const style = getComputedStyle(root);

      this.dashboard = this.changeDashboardItems([
        {
          id: 1,
          cols: 4,
          rows: 1.25,
          y: 0,
          x: 0,
          dragEnabled: true,
          resizeEnabled: true,
          wid5: true,
          widOptions: {
            amount: (res.tasks as any as Task[])?.filter(
              (x) => x.completed == false && x?.principle?.name == this.jwtService.getPreferredUsername()
            )?.length,
          },
        },
        {
          id: 2,
          cols: 4,
          rows: 1.25,
          y: 0,
          x: 4,
          dragEnabled: true,
          resizeEnabled: true,
          wid2: true,
          widOptions: {
            amount: (res.riskItems as any as RiskItem[]).length,
          },
        },
        {
          id: 3,
          cols: 4,
          rows: 1.25,
          y: 0,
          x: 8,
          dragEnabled: true,
          resizeEnabled: true,
          wid1: true,
          widOptions: {
            oldValue: taskData.before,
            currentValue: taskData.today,
          },
        },
        {
          id: 4,
          cols: 12,
          rows: 3,
          y: 1.25,
          x: 0,
          dragEnabled: true,
          resizeEnabled: true,
          riskMethod: true,
        },
        {
          id: 5,
          cols: 8,
          rows: 4,
          y: 4.25,
          x: 0,
          dragEnabled: true,
          resizeEnabled: true,
          hasContent: true,
          table1: true,
        },
        {
          id: 6,
          cols: 8,
          rows: 4,
          y: 8.25,
          x: 6,
          dragEnabled: true,
          resizeEnabled: true,
          hasContent: true,
          table2: true,
        },
        {
          id: 7,
          cols: 8,
          rows: 4,
          y: 12.25,
          x: 0,
          dragEnabled: true,
          resizeEnabled: true,
          hasContent: true,
          chart1: true,
          chartData: {
            labels: res.activeMeth.data.thresholdValuesDto.map((x) => x.name),
            datasets: [
              {
                label: 'Risk Values',
                backgroundColor: res.activeMeth.data.thresholdValuesDto.map((x) => x.color),
                data: riskData,
              },
            ],
          },
          chartOptions: {
            plugins: {
              legend: {
                labels: {
                  color: '#868C9B',
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: '#ebedef',
                },
                grid: {
                  color: 'rgba(255,255,255,0.2)',
                },
              },
              y: {
                ticks: {
                  color: '#ebedef',
                },
                grid: {
                  color: 'rgba(255,255,255,0.2)',
                },
              },
            },
          },
        },
        {
          id: 8,
          cols: 4,
          rows: 4.5,
          y: 4.25,
          x: 8,
          dragEnabled: true,
          resizeEnabled: true,
          chart2: true,
          listData: controlData,
          totalAmount: this.getTotalAmount(controlData),
          chartData: {
            labels: Object.keys(controlData),
            datasets: [
              {
                data: Object.keys(controlData).map((x) => controlData[x]),
                backgroundColor: Object.keys(controlData).map((x) =>
                  style.getPropertyValue(`--${this.badgeColors[x]}-500`)
                ),
                // [
                //     "#66BB6A",
                //     "#FFA726",
                //     '#AB47BC',
                // ],
                // hoverBackgroundColor: [
                //     "#81C784",
                //     "#FFB74D",
                //     '#FF47BC',
                // ],
                borderColor: 'transparent',
                fill: true,
              },
            ],
          },
          chartOptions: {
            plugins: {
              legend: {
                labels: {
                  color: '#868C9B',
                },
              },
            },
          },
        },
        {
          id: 9,
          cols: 4,
          rows: 5.25,
          y: 8.75,
          x: 8,
          dragEnabled: true,
          resizeEnabled: true,
          chart3: true,
          listData: issueData,
          totalAmount: this.getTotalAmount(issueData),
          chartData: {
            labels: Object.keys(issueData),
            datasets: [
              {
                data: Object.keys(issueData).map((x) => issueData[x]),
                backgroundColor: Object.keys(issueData).map((x) =>
                  style.getPropertyValue(`--${this.badgeColors[x]}-500`)
                ),
                borderColor: 'transparent',
                fill: true,
              },
            ],
          },
          chartOptions: {
            plugins: {
              legend: {
                labels: {
                  color: '#868C9B',
                },
              },
              datalabels: {
                formatter: (value, ctx) => {
                  let sum = 0;
                  let dataArr = ctx.chart.data.datasets[0].data;
                  dataArr.map((data) => {
                    sum += data;
                  });
                  let percentage = ((value * 100) / sum).toFixed(2) + '%';
                  return percentage;
                },
                color: '#fff',
              },
            },
          },
        },
        {
          id: 10,
          cols: 4,
          rows: 4.5,
          y: 14,
          x: 8,
          dragEnabled: true,
          resizeEnabled: true,
          chart4: true,
          listData: placeHolderRisks,
          totalAmount: this.getTotalAmount(placeHolderRisks),
          chartData: {
            labels: Object.keys(placeHolderRisks),
            datasets: [
              {
                data: Object.keys(placeHolderRisks).map((x) => placeHolderRisks[x]),
                backgroundColor: Object.keys(placeHolderRisks).map((x) =>
                  style.getPropertyValue(`--${this.badgeColors[x]}-500`)
                ),
                borderColor: 'transparent',
                fill: true,
              },
            ],
          },
          chartOptions: {
            plugins: {
              legend: {
                labels: {
                  color: '#868C9B',
                },
              },
            },
            elements: {
              center: {
                text: 'Acceptable Risk: ' + res?.activeMeth?.data?.acceptableRisk,
                color: style.getPropertyValue(`--blue-500`), // Default is #000000
                fontStyle: 'Arial', // Default is Arial
                sidePadding: 20, // Default is 20 (as a percentage)
                minFontSize: 15, // Default is 20 (in px), set to false and text will not wrap.
                maxFontSize: 40, // Default is 20 (in px), set to false and text will not wrap.
                lineHeight: 25, // Default is 25 (in px), used for when text wraps
              },
            },
          },
        },
      ]);
    });
  }
  parseIssuesData(issues: Issue[]) {
    let ret = {
      [Issue.CriticalityEnum.Critical]: 0,
      [Issue.CriticalityEnum.High]: 0,
      [Issue.CriticalityEnum.Moderate]: 0,
      [Issue.CriticalityEnum.Low]: 0,
      [Issue.CriticalityEnum.Info]: 0,
    };
    issues.forEach((issue) => {
      ret[issue.criticality]++;
    });

    return ret; //Object.keys(ret).map(x=> ret[x]);
  }
  parseControlsData(controls: Control[]) {
    let ret = {
      [Control.ControlClassificationEnum.Corrective]: 0,
      [Control.ControlClassificationEnum.Preventive]: 0,
      [Control.ControlClassificationEnum.Detective]: 0,
    };
    controls.forEach((item) => {
      ret[item.controlClassification]++;
    });
    return ret; //Object.keys(ret).map(x=> ret[x]);
  }
  parseTasksData(tasks: Task[]) {
    let ret = {
      today: 0,
      before: 0,
    };
    let today = new Date();
    today.setHours(0, 0, 0);
    tasks.forEach((item) => {
      ret[new Date(item.creationDate) < today ? 'before' : 'today']++;
    });
    return ret;
  }
  parseRisksData(risks: RiskItem[], activeMeth: RiskMethodology) {
    let ret = {};
    activeMeth.thresholdValuesDto.forEach((threshold) => {
      ret[threshold.name] = 0;
    });

    risks.forEach((item) => {
      let threshold = find(activeMeth?.thresholdValuesDto, function (o) {
        return o.max >= item.residualRisk && o.min <= item.residualRisk;
      });
      if (threshold != undefined) ret[threshold.name]++;
    });
    return Object.keys(ret).map((x) => ret[x]);
  }
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  removeItem($event: MouseEvent | TouchEvent, item): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  addItem(): void {
    this.dashboard.push({ x: 0, y: 0, cols: 1, rows: 1 });
  }
  eventsCols: IColumn[] = [
    {
      name: 'Id',
      key: 'code',
      dataType: DataTypeEnum.CodeWithStatus,
      passRowAsData: true,
      sortDisabled: true,
    },
    {
      name: 'Record Status',
      key: 'recordStatus',
      dataType: DataTypeEnum.Badge,
      sortDisabled: true,
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      sortDisabled: true,
    },
    // {
    //     name: "Owner",
    //     key: "owner",
    //     dataType: DataTypeEnum.UserListView,
    //     sortDisabled:true
    // },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.CodeLink,
      sortDisabled: true,
    },
  ];
  eventsTableData;
  eventsTableEvent: ITableLoadEvent = new ITableLoadEvent();
  onEventsTableFilterChange(tableEvent) {
    this.eventsTableEvent = tableEvent;
    this.subs.sink = this.eventsDataService
      .search<any>({ ...this.eventsTableEvent.pageInfo.pagination }, { filters: this.eventsTableEvent.filters })
      .subscribe({
        next: (res: any) => {
          this.eventsTableData = res.content;
          this.eventsTableEvent.pageInfo.totalElements = res.totalElements;
        },
        error: (error) => { },
        complete: () => { },
      });
  }
  objectivesCols: IColumn[] = [
    {
      name: 'Id',
      key: 'code',
      dataType: DataTypeEnum.CodeWithStatus,
      passRowAsData: true,
      sortDisabled: true,
    },
    {
      name: 'Record Status',
      key: 'recordStatus',
      dataType: DataTypeEnum.Badge,
      sortDisabled: true,
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      sortDisabled: true,
    },
    // {
    //     name: "Owner",
    //     key: "owner",
    //     dataType: DataTypeEnum.UserListView,
    //     sortDisabled:true
    // },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.CodeLink,
      sortDisabled: true,
    },
  ];
  objectivesTableData;
  objectivesTableEvent: ITableLoadEvent = new ITableLoadEvent();
  onObjectivesTableFilterChange(tableEvent) {
    this.objectivesTableEvent = tableEvent;
    this.subs.sink = this.objectivesDataService
      .search<any>({ ...this.objectivesTableEvent.pageInfo.pagination }, { filters: this.objectivesTableEvent.filters })
      .subscribe({
        next: (res: any) => {
          this.objectivesTableData = res.content;
          this.objectivesTableEvent.pageInfo.totalElements = res.totalElements;
        },
        error: (error) => { },
        complete: () => { },
      });
  }
  changeDashboardItems(newDashboardData: any[]) {
    const existingDashboard: any[] = localStorage.getItem('main-dashboard')
      ? JSON.parse(localStorage.getItem('main-dashboard'))
      : newDashboardData;
    let ret: any[] = [];
    newDashboardData.forEach((item) => {
      let matchingItem = existingDashboard.find((x) => x?.id == item?.id);
      ret.push({
        ...item,
        cols: matchingItem?.cols || item?.cols,
        rows: matchingItem?.rows || item?.rows,
        y: matchingItem?.y || item?.y,
        x: matchingItem?.x || item?.x,
      });
    });
    return ret;
  }
  getTotalAmount(obj: { [x: string]: number }) {
    let total = 0;
    Object.keys(obj).forEach((item) => {
      total += obj[item];
    });
    return total;
  }
}
