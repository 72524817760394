<div class="exception-body">
  <div class="exception-panel gap-4" style="margin-top: -100px">
    <img class="w-3" src="assets/layout/images/logo-dark.png" alt="Logo image" />
    <p>Welcome To Sightflare</p>
    <button type="button" pButton label="Go to home" [routerLink]="['/']"></button>
  </div>
  <div class="exception-footer">
    <img
      [src]="'assets/layout/images/logo-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
      class="exception-logo"
    />
    <img
      [src]="'assets/layout/images/appname-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
      class="exception-appname"
    />
  </div>
</div>
