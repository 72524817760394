import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './app.welcome.component.html',
})
export class AppWelcomeComponent {
  constructor(public app: AppComponent) { }
}
