import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppMainComponent } from './app.main.component';
import { AuthState, SignOut } from './core/state';

@Component({
  selector: 'app-inlinemenu',
  templateUrl: './app.inlinemenu.component.html',
  animations: [
    trigger('inline', [
      state(
        'hidden',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AppInlineMenuComponent implements OnInit {
  loggedInUser$: Observable<any>;
  // @Select(AuthState.loggedInUser) loggedInUser$: Observable<any>;

  constructor(
    public appMain: AppMainComponent,
    private store: Store,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.loggedInUser$ = this.store.select<any>(AuthState.loggedInUser);

    this.store.subscribe((res) => {});
    this.loggedInUser$.subscribe((res) => {});
  }
  signOut(): void {
    this.store.dispatch(new SignOut());
  }
  onConfigButtonClick(event) {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }
}
