import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetLoading } from './loading.actions';

export class LoadingStateModel {
  loading: boolean;
}

@State<LoadingStateModel>({
  name: 'loading',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class LoadingState {
  @Selector()
  static Loading(state: LoadingStateModel): boolean {
    return state.loading;
  }
  constructor() {}

  @Action(SetLoading)
  toggleLoading({ setState }: StateContext<LoadingStateModel>, { state }: SetLoading) {
    setState(
      patch<LoadingStateModel>({
        loading: state,
      })
    );
  }
}
