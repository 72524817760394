/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { isDevEnv, isProductionEnv } from '@shared/classes';
import { StaticValuesCodes } from 'app/modules/users/services/data/static-values-data.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (!isDevEnv()) {
  // Override console.debug (or any other console method) in production
  console.debug = () => {}; // This makes console.debug a no-op in production
}

if (isProductionEnv() || true) {
  enableProdMode();
}
if (localStorage.getItem(StaticValuesCodes.TENANT_NAME)) {
  const titleElement = document.getElementById('index-title-tag');
  titleElement.textContent = localStorage.getItem(StaticValuesCodes.TENANT_NAME);
}
// if(localStorage.getItem(StaticValuesCodes.BANNER)){
//     const titleElement = document.getElementById('below-loader-text');
//     titleElement.innerText = localStorage.getItem(StaticValuesCodes.BANNER);
// }
fetch(environment.configuration + '/static-values/static/value/' + StaticValuesCodes.TENANT_NAME)
  .then((response) => response.text())
  .then((data) => {
    const titleElement = document.getElementById('index-title-tag');
    titleElement.textContent = (data || 'Sight Flare').startsWith('{') ? 'Sight Flare' : data || 'Sight Flare';
    localStorage.setItem(
      StaticValuesCodes.TENANT_NAME,
      (data || 'Sight Flare').startsWith('{') ? 'Sight Flare' : data || 'Sight Flare'
    );
  })
  .catch((error) => {
    console.error('Error:', error);
  });
fetch('assets/public/version')
  .then((response) => response.text())
  .then((data) => {
    if (!localStorage.getItem('site_version')) {
      localStorage.setItem('site_version', data);
      console.warn('no site version');
    } else {
      const oldVersion = localStorage.getItem('site_version');
      if (oldVersion != data) {
        console.warn('mismatch site version');
        localStorage.setItem('site_version', data);
        console.warn('RELOADING');
        window?.location?.reload();
        console.warn('RELOADED');
      }
    }
  })
  .catch((error) => {
    console.error('Error:', error);
  });
// fetch(environment.configuration + '/static-values/static/value/' + StaticValuesCodes.BANNER)
//   .then((response) => response.text())
//   .then((data) => {

//     const titleElement = document.getElementById('below-loader-text');
//     titleElement.innerText = (data || 'Sight Flare').startsWith('{') ? 'Sight Flare' : (data || 'Sight Flare');
//     localStorage.setItem(StaticValuesCodes.BANNER,(data || 'Sight Flare').startsWith('{') ? 'Sight Flare' : (data || 'Sight Flare'));
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });
// fetch(environment.configuration + '/static-values/static/value/' + StaticValuesCodes.TENANT_DESCRIPTION)
//   .then((response) => response.text())
//   .then((data) => {

//     const linkElement = document.getElementById('index-description-tag');
//     linkElement.setAttribute( 'content', data);
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });

// const linkElement = document.getElementById('favicon-img');
// linkElement.setAttribute( 'href', environment.configuration + '/static-values/static/value/' + StaticValuesCodes.FAVICON);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
    if (err?.error == 'Timeout when waiting for 3rd party check iframe message.') {
      console.warn('AUTO RETRY INITIATED DUE TO AUTH TIMEOUT');
      window?.location?.reload();
    }
  });
