<div class="layout-breadcrumb-container md:flex-row flex-column gap-2 align-items-start">
  <div class="layout-breadcrumb-left-items">
    @if (appMain.isStatic() && !appMain.isMobile()) {
      <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>
    }

    <p-breadcrumb [model]="items" styleClass="layout-breadcrumb py-2"></p-breadcrumb>
  </div>
  <div class="layout-breadcrumb-right-items align-self-end">
    <div class="flex gap-2 mr-5">
      <i
        class="pi pi-search p-text-secondary cursor-pointer hover:text-gray-400"
        (click)="showGlobalSearchPopup()"
        [pTooltip]="'general.cards.globalSearch.globalSearch' | translate"
        tooltipPosition="left"
        style="
          font-size: 1.5rem;
          height: 32px;
          width: 32px;
          align-items: center;
          display: flex;
          justify-content: center;
        "
      ></i>
      <!-- <app-view-mode-switch class="" *ngIf="breadcrumbService.showViewModeSwitch"
    [moduleKeyword]="breadcrumbService.currentModuleKeyword"></app-view-mode-switch> -->
      <!-- <app-drop-down-input
    *ngIf="viewModeService.isDashboardPage"
    class="w-fit"
    [floatLabel]="false"
    [label]="''"
    [placeholder]="'Select Dashboard'"
    [name]="'dashboard'"
    [optionLabel]="'label'"
    [items]="dashboardList"
    [optionValue]="'value'"
    [control]="dashboardDropdown"
    [viewMode]="'edit'"
    [badgeView]="false"
    [showClear]="false"
    (onChanges)="onChanges($event)"
  ></app-drop-down-input> -->
      @if (breadcrumbService.showTreeSwitch) {
        <app-tree-mode-switch class="" [moduleKeyword]="breadcrumbService.currentModuleKeyword"></app-tree-mode-switch>
      }
      @if (breadcrumbService.itemId && breadcrumbService.showViewModeSwitch) {
        <i
          class="pi pi-eye p-text-secondary cursor-pointer hover:text-gray-400"
          (click)="watchingPanel.toggle($event)"
          pTooltip="Watching"
          tooltipPosition="left"
          style="
            font-size: 1.5rem;
            height: 32px;
            width: 32px;
            align-items: center;
            display: flex;
            justify-content: center;
          "
        ></i>
      }

      <!-- <app-button *ngIf="breadcrumbService.itemId && breadcrumbService.showViewModeSwitch" (onClickAction)="watchingPanel.toggle($event)" pTooltip="Watching" tooltipPosition="left"
                [action]="{
            id: 99,
            label: '',
            icon: 'pi pi-eye',
            iconPos: 'left',
            buttonStyle: 'text',
            color:'secondary'
        }"></app-button> -->
      <div>
        <!-- Notification Button -->
        @if (socketService?.totalNotificationCount > 0) {
          <div
            *ngxPermissionsOnly="readNotificationsPermission"
            style="
              font-size: 1.5rem;
              height: 32px;
              width: 32px;
              align-items: center;
              display: flex;
              justify-content: center;
            "
          >
            <i
              class="pi pi-bell p-text-secondary cursor-pointer hover:text-gray-400"
              style="font-size: inherit"
              (click)="notificationsPanel.toggle($event)"
              pBadge
              [value]="socketService?.totalNotificationCount > 99 ? '+99' : socketService?.totalNotificationCount + ''"
              severity="danger"
              [pTooltip]="'general.notificationTray.notifications' | translate"
              tooltipPosition="left"
            ></i>
          </div>
        }
        @if (!(socketService?.totalNotificationCount > 0)) {
          <i
            *ngxPermissionsOnly="readNotificationsPermission"
            class="pi pi-bell p-text-secondary cursor-pointer hover:text-gray-400"
            (click)="notificationsPanel.toggle($event)"
            style="
              font-size: 1.5rem;
              height: 32px;
              width: 32px;
              align-items: center;
              display: flex;
              justify-content: center;
            "
            [pTooltip]="'general.notificationTray.notifications' | translate"
            tooltipPosition="left"
          ></i>
        }
        <!-- <div *ngIf="socketService?.notifications?.length > 0" pBadge style="font-size: 0.5rem" [value]="(socketService?.notifications?.length + '')" severity="danger"></div> -->
        <!-- <app-button (onClickAction)="notificationsPanel.toggle($event)" pTooltip="Notifications" tooltipPosition="left"
            [action]="{
            id: 99,
            label: '',
            icon: 'pi pi-bell',
            iconPos: 'left',
            buttonStyle: 'text',
            color:'secondary'
        }"></app-button> -->
      </div>
    </div>
    <!-- <a tabindex="0" class="search-icon" (click)="appMain.searchActive = true; appMain.searchClick = true;">
<i class="pi pi-search"></i>
</a>

<div class="search-wrapper" [ngClass]="{'active-search-wrapper': appMain.searchActive}">
  <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input appMaxCharacterValidator type="text" pInputText placeholder="Search..." [(ngModel)]="search" (click)="appMain.searchClick=true"/>
  </span>
</div>

<button pButton pRipple type="button" label="Today" icon="pi pi-bookmark" *ngIf="!appMain.isMobile()"
class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button>
<button pButton pRipple type="button" icon="pi pi-bookmark" *ngIf="appMain.isMobile()"
class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button> -->
  </div>
</div>
<p-overlayPanel #notificationsPanel [showCloseIcon]="false" [styleClass]="''">
  <ng-template pTemplate>
    <app-notifications-tray></app-notifications-tray>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #watchingPanel [showCloseIcon]="false" [styleClass]="''">
  <ng-template pTemplate>
    <app-watching-tray [itemId]="breadcrumbService.itemId"></app-watching-tray>
  </ng-template>
</p-overlayPanel>
