import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { forkJoin, of } from 'rxjs';
import { loggedInUser } from '../../models';
import { JWTTokenService } from '../../services/JWT-token/jwttoken.service';
import { CheckAuth, SignOut } from './auth.actions';

export class AuthStateModel {
  isAuthenticated: boolean;
  accessToken: string;
  user: loggedInUser;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    isAuthenticated: false,
    accessToken: null,
  },
})
@Injectable()
export class AuthState {
  // Selectors

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    return state.isAuthenticated;
  }

  @Selector()
  static loggedInUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  constructor(
    private keyClockService: KeycloakService,
    private JWTTokenService: JWTTokenService
  ) {}
  @Action(CheckAuth)
  checkAuth({ setState }: StateContext<AuthStateModel>) {
    forkJoin({
      token: this.keyClockService.getToken(),
      isLoggedIn: of(this.keyClockService.isLoggedIn()),
    }).subscribe(({ token, isLoggedIn }) => {
      this.JWTTokenService.setToken(token);
      setState(
        patch<AuthStateModel>({
          accessToken: token,
          isAuthenticated: isLoggedIn,
          user: this.JWTTokenService.getDecodeToken(),
        })
      );
    });
  }

  @Action(SignOut)
  signOut({ setState }: StateContext<AuthStateModel>) {
    this.keyClockService.logout(environment.logoutRedirectUrl).then((res) => {
      setState(
        patch<AuthStateModel>({
          isAuthenticated: false,
          user: null,
          accessToken: null,
        })
      );
    });
  }
}
