<!-- <app-base-page [breadCrumb]="breadCrumb">
<ng-container page-header>
  <h4>Example with card container enabled (default/omit is true)</h4>
</ng-container>
any content written directly is inside the body
<p>body example</p>
<ng-container page-footer>
  <h6>footer content (below body)</h6>
</ng-container>
</app-base-page> -->
<app-base-page [breadCrumb]="breadCrumb" [cardContainer]="false">
  <!-- <div class="lg:w-8 w-full">
  <div class="mx-auto">
    <img class="lg:w-4 w-6" src="assets/layout/images/logo.png" alt="Logo image" />
    <h1>{{ "Welcom to Sightflare" | titlecase }}</h1>
    <p>
      advises to start with the available information about your
      organization and then to expand it over time as your GRC team
      gains more familiarity and experience. Review Frequency: We
      would recommend that this document is reviewed as part of an
      annual exercise which should include significant business
      involvement to ensure that changed requirements are captured and
      feedback obtained.
    </p>
  </div>
</div> -->
</app-base-page>
<gridster [options]="options">
  @for (item of dashboard; track item; let i = $index) {
    <gridster-item [item]="item" class="card">
      <div class="w-full h-full">
        @if (item.riskMethod) {
          <app-dashlet [config]="{ title: 'Active Risk Methodology Chart' }">
            <app-RiskMethodology [showSummaryOnly]="true"></app-RiskMethodology>
          </app-dashlet>
        }
        @if (item.chart1) {
          <app-dashlet [config]="{ title: 'Risks Based on Thresholds' }">
            <div style="width: 88%; align-self: 'center'; justify-self: center">
              <p-chart type="bar" [data]="item.chartData" [options]="item.chartOptions"></p-chart>
            </div>
          </app-dashlet>
        }
        @if (item.chart2) {
          <app-dashlet class="widget-country-graph" [config]="{ title: 'Controls' }">
            <p-chart
              type="doughnut"
              [data]="item.chartData"
              [options]="item.chartOptions"
              [plugins]="[plugin]"
              style="width: '75%'; align-self: 'center'; justify-self: center"
            ></p-chart>
            <div class="country-content">
              <ul>
                @for (listItem of item.listData | keyvalue; track listItem) {
                  <li class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      <div [class]="'color bg-' + badgeColors[$any(listItem?.key)] + '-500 shadow-1'"></div>
                      <span>{{ listItem.key }}</span>
                    </div>
                    <span [pTooltip]="'Amount: ' + listItem.value" tooltipPosition="top"
                      >{{
                        item.totalAmount
                          ? (($any(listItem.value) / item.totalAmount) * 100 | parseToFixedNumber: 'int')
                          : '0'
                      }}%</span
                    >
                  </li>
                }
              </ul>
            </div>
          </app-dashlet>
        }
        @if (item.chart3) {
          <app-dashlet class="widget-country-graph" [config]="{ title: 'Issues' }">
            <p-chart
              type="doughnut"
              [data]="item.chartData"
              [options]="item.chartOptions"
              [plugins]="[plugin]"
              style="width: '75%'; align-self: 'center'; justify-self: center"
            ></p-chart>
            <div class="country-content">
              <ul>
                @for (listItem of item.listData | keyvalue; track listItem) {
                  <li class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      <div [class]="'color bg-' + badgeColors[$any(listItem?.key)] + '-500 shadow-1'"></div>
                      <span>{{ listItem.key }}</span>
                    </div>
                    <span [pTooltip]="'Amount: ' + listItem.value" tooltipPosition="top"
                      >{{
                        item.totalAmount
                          ? (($any(listItem.value) / item.totalAmount) * 100 | parseToFixedNumber: 'int')
                          : '0'
                      }}%</span
                    >
                  </li>
                }
              </ul>
            </div>
          </app-dashlet>
        }
        @if (item.chart4) {
          <app-dashlet class="widget-country-graph" [config]="{ title: 'Risks' }">
            <p-chart
              type="doughnut"
              [data]="item.chartData"
              [options]="item.chartOptions"
              [plugins]="[plugin]"
              style="width: '75%'; align-self: 'center'; justify-self: center"
            ></p-chart>
            <div class="country-content">
              <ul>
                @for (listItem of item.listData | keyvalue; track listItem) {
                  <li class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      <div [class]="'color bg-' + badgeColors[$any(listItem?.key)] + '-500 shadow-1'"></div>
                      <span>{{ listItem.key }}</span>
                    </div>
                    <span [pTooltip]="'Amount: ' + listItem.value" tooltipPosition="top"
                      >{{
                        item.totalAmount
                          ? (($any(listItem.value) / item.totalAmount) * 100 | parseToFixedNumber: 'int')
                          : '0'
                      }}%</span
                    >
                  </li>
                }
              </ul>
            </div>
          </app-dashlet>
        }
        @if (item.table1) {
          <app-dashlet [config]="{ title: 'Events' }">
            <app-table
              [cols]="eventsCols"
              [inputData]="eventsTableData"
              [pageInfo]="eventsTableEvent.pageInfo"
              [selectionMode]="null"
              [rowsPerPageOptions]="null"
              [offlineConfig]="{
                lazy: true,
                paginator: true,
                showActionBar: false,
              }"
              (filtersChanged)="onEventsTableFilterChange($event)"
            ></app-table>
          </app-dashlet>
        }
        @if (item.table2) {
          <app-dashlet [config]="{ title: 'Objectives' }">
            <app-table
              [cols]="objectivesCols"
              [inputData]="objectivesTableData"
              [pageInfo]="objectivesTableEvent.pageInfo"
              [selectionMode]="null"
              [rowsPerPageOptions]="null"
              [offlineConfig]="{
                lazy: true,
                paginator: true,
                showActionBar: false,
              }"
              (filtersChanged)="onObjectivesTableFilterChange($event)"
            ></app-table>
          </app-dashlet>
        }
        @if (item.wid1) {
          <div>
            <app-dashlet [config]="{ title: 'New Tasks', icon: 'pi pi-calendar-plus', color: 'green' }">
              <app-periodical-widget [config]="item.widOptions"></app-periodical-widget>
            </app-dashlet>
            <!-- <app-dashlet [config]="{title:''}">
            <div class="col-12 p-0">
              <div class=" ">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-500 font-medium mb-3">Tasks To Do</span>
                    <div class="text-900 font-medium text-xl">3</div>
                  </div>
                  <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                    style="width: 2.5rem; height: 2.5rem;">
                    <i class="pi pi-user text-green-500 text-xl"></i>
                  </div>
                </div>
                <span class="text-green-500 font-medium">%52+ </span>
                <span class="text-500">since last week</span>
              </div>
            </div>
          </app-dashlet> -->
          </div>
        }
        @if (item.wid5) {
          <div>
            <app-dashlet [config]="{ title: 'My To-Do Tasks', icon: 'pi pi-calendar', color: 'blue' }">
              <div class="p-3 text-900 font-medium text-3xl text-xl text-center">
                {{ item?.widOptions?.amount || 0 }}
              </div>
            </app-dashlet>
          </div>
        }
        @if (item.wid2) {
          <div>
            <app-dashlet [config]="{ title: 'Total Risks', icon: 'pi pi-exclamation-triangle', color: 'yellow' }">
              <div class="p-3 text-900 font-medium text-3xl text-xl text-center">
                {{ item?.widOptions?.amount || 0 }}
              </div>
            </app-dashlet>
          </div>
        }
        @if (item.wid3) {
          <div>
            <app-dashlet [config]="{ title: 'Acceptable Risk', icon: 'pi pi-check-circle', color: 'blue' }">
              <div class="flex justify-content-between">
                <app-badge-item
                  class="p-3 text-900 font-medium text-2xl text-center"
                  [styleClass]="'p-3 text-900 font-medium text-2xl text-center'"
                  [displayText]="true"
                  [status]="'red'"
                  [text]="item?.widOptions?.belowAmount || '0'"
                ></app-badge-item>
                <app-badge-item
                  class="p-3 text-900 font-medium text-3xl text-center"
                  [styleClass]="'p-3 text-900 font-medium text-3xl text-center'"
                  [displayText]="true"
                  [status]="'blue'"
                  [text]="item?.widOptions?.amount || '0'"
                ></app-badge-item>
                <app-badge-item
                  class="p-3 text-900 font-medium text-2xl text-center"
                  [styleClass]="'p-3 text-900 font-medium text-2xl text-center'"
                  [displayText]="true"
                  [status]="'green'"
                  [text]="item?.widOptions?.aboveAmount || '0'"
                ></app-badge-item>
                <!-- <div class="p-3 text-900 font-medium text-2xl text-center">{{item?.widOptions?.amount || 0}}</div>
              <div class="p-3 text-900 font-medium text-3xl text-center">{{item?.widOptions?.amount || 0}}</div>
              <div class="p-3 text-900 font-medium text-2xl text-center">{{item?.widOptions?.amount || 0}}</div> -->
              </div>
            </app-dashlet>
          </div>
        }
        @if (item.wid4) {
          <div>
            <div class="col-12">
              <div class="shadow-2 p-3 border-1 border-50 border-round">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block text-500 font-medium mb-3">Risks in Range 1200 - 2500</span>
                    <div class="text-900 font-medium text-xl">3</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-red-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user text-red-500 text-xl"></i>
                  </div>
                </div>
                <!-- <span class="text-green-500 font-medium">%52+ </span> -->
                <!-- <span class="text-500">since last week</span> -->
              </div>
            </div>
          </div>
        }
      </div>
    </gridster-item>
  }
</gridster>
