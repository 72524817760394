import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { isProductionEnv } from '@shared/classes';
import { CORE_STATES } from './state';
@NgModule({
  imports: [
    // Here w'll define the general states like (Auth,Lang,Landing)
    NgxsModule.forRoot([...CORE_STATES], {
      developmentMode: true,
    }),
    /**
     *  Tools used for logging the states interaction on the browser console
     *  It is disabled on the production mode
     */
    NgxsLoggerPluginModule.forRoot({ disabled: isProductionEnv()}),
    /**
     * Now this library will be commeted out
     * When we need to store anything in the localstorage
     * we can add them here as a {key:stateName}
     */
    // NgxsStoragePluginModule.forRoot(),
  ],
  providers: [],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
