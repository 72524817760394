<div
  class="layout-menu-wrapper"
  [ngClass]="{ 'layout-sidebar-active': appMain.sidebarActive }"
  (click)="appMain.onSidebarClick($event)"
  (mouseover)="appMain.onSidebarMouseOver($event)"
  (mouseleave)="appMain.onSidebarMouseLeave($event)"
  style="z-index: 999"
>
  @if (!appMain.isHorizontal() || appMain.isMobile()) {
    <div class="menu-logo">
      <a href="#" class="logo">
        <!-- <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"> -->
        <img [src]="app.colorScheme === 'light' ? configService.logoImgLight : configService.logoImgDark" />
      </a>
      @if (!appMain.isSlim() || appMain.isMobile()) {
        <a href="#" class="app-name">
          <!-- <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"/> -->
          <img [src]="app.colorScheme === 'light' ? configService.appNameImgLight : configService.appNameImgDark" />
        </a>
      }
      <a href="#" class="menu-pin" (click)="appMain.onToggleMenu($event)">
        @if (appMain.isOverlay()) {
          <span class="pi pi-times"></span>
        }
        @if (appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive) {
          <span class="pi pi-unlock"></span>
        }
        @if (appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive) {
          <span class="pi pi-lock"></span>
        }
      </a>
    </div>
    <div class="nav-menu-search-input p-input-icon-left">
      <i class="pi pi-search text-2xl search-icon"></i>
      <input
        (keyup)="search($any($event?.target)?.value)"
        class="w-full search-input"
        type="text"
        pInputText
        [placeholder]="'general.fields.searchKeyword.placeholder' | translate: 'Search...'"
      />
    </div>
  }

  <div class="layout-menu-container">
    <ul class="layout-menu">
      @for (item of model; track item; let i = $index) {
        <!-- <ng-container *ngxPermissionsOnly="item.permission"> -->
        <ng-container>
          @if (!item.separator) {
            <li app-menuitem class="app-menu-parent" [item]="item" [index]="i" [root]="true"></li>
          }
        </ng-container>
        @if (item.separator) {
          <li class="menu-separator"></li>
        }
      }
    </ul>
  </div>

  <app-inlinemenu></app-inlinemenu>
</div>
