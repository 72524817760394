import { isProductionEnv } from '@shared/classes';
import { environment } from 'environments/environment';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService) {
  var refreshTokenTimeout;

  return () =>
    keycloak
      .init({
        config: {
          url: environment.keyCloak.config.url,
          realm: environment.keyCloak.config.realm,
          clientId: environment.keyCloak.config.clientId,
        },
        initOptions: {
          enableLogging: !isProductionEnv(),
          onLoad: environment.keyCloak.initOptions.onLoad as 'check-sso' | 'login-required',
          silentCheckSsoRedirectUri: environment.keyCloak.initOptions.silentCheckSsoRedirectUri,
        },
        bearerExcludedUrls: [environment.configuration + '/static-values/static/value'],
      })
      .then((x) => {
        // const timeoutTime = (this.keycloak.getKeycloakInstance().refreshTokenParsed.exp - (new Date().getTime()));
        // if(timeoutTime > 0){
        //     clearTimeout(this.refreshTokenTimeout);
        //     this.refreshTokenTimeout = setTimeout(() => {
        //         this.keycloak.login();
        //     }, timeoutTime);
        // }else{
        //     this.keycloak.login();
        // }

        keycloak.keycloakEvents$.subscribe({
          next(event) {
            // if (event.type == KeycloakEventType.OnTokenExpired) {
            //     _this.keycloak.updateToken().then(function (refreshed) {
            //         if (refreshed) {
            //             // alert('Token was successfully refreshed');

            //             _this.store.dispatch(new CheckAuth());
            //         } else {
            //             // alert('Token is still valid');
            //             // this.store.dispatch(new SignOut());
            //             _this.store.dispatch(new CheckAuth());
            //         }
            //     }).catch(function () {
            //         // alert('Failed to refresh the token, or the session has expired');

            //         // _this.store.dispatch(new SignOut());
            //         _this.store.dispatch(new CheckAuth());

            //     });
            // }

            if (
              event.type == KeycloakEventType.OnAuthLogout ||
              event.type == KeycloakEventType.OnAuthError ||
              event.type == KeycloakEventType.OnAuthRefreshError
            ) {
              // _this.store.dispatch(new SignOut());
              // _this.store.dispatch(new CheckAuth());

              keycloak.login();
            }
            if (event.type == KeycloakEventType.OnAuthRefreshSuccess) {
              const exp = keycloak.getKeycloakInstance()?.refreshTokenParsed?.exp;
              const timeoutTime2 = exp * 1000 - Date.now();
              if (timeoutTime2 > 0) {
                clearTimeout(refreshTokenTimeout);
                refreshTokenTimeout = setTimeout(
                  () => {
                    keycloak.logout();
                  },
                  timeoutTime2 + 5 * 1000
                ); // added 5 seconds to avoid token refresh adjustment by keycloak
              } else {
                keycloak.logout();
              }
            }
          },
        });
      });
}
