import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LocaleService } from '@core/services/locale/locale.service';
import { Subscription } from 'rxjs';
import { AppComponent } from './app.component';
import { AppConfig, ConfigService } from './app.config.service';
import { AppMainComponent } from './app.main.component';
import { StaticValuesCodes, StaticValuesDataService } from './modules/users/services/data/static-values-data.service';
@Component({
  selector: 'app-config',
  template: `
    <!-- <a style="cursor: pointer" id="layout-config-button" class="layout-config-button" (click)="onConfigButtonClick($event)">
        <i class="pi pi-cog"></i>
        </a> -->
    <div
      class="layout-config"
      [ngClass]="{ 'layout-config-active': appMain.configActive }"
      (click)="appMain.onConfigClick($event)"
    >
      <h5>Menu Type</h5>
      <div class="field-radiobutton">
        <p-radioButton
          name="layoutMode"
          value="static"
          [(ngModel)]="app.menuMode"
          inputId="layoutMode1"
        ></p-radioButton>
        <label for="layoutMode1">Static</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="layoutMode"
          value="overlay"
          [(ngModel)]="app.menuMode"
          inputId="layoutMode2"
        ></p-radioButton>
        <label for="layoutMode2">Overlay</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="layoutMode" value="slim" [(ngModel)]="app.menuMode" inputId="layoutMode3"></p-radioButton>
        <label for="layoutMode3">Slim</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="layoutMode"
          value="horizontal"
          [(ngModel)]="app.menuMode"
          inputId="layoutMode4"
        ></p-radioButton>
        <label for="layoutMode4">Horizontal</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="layoutMode"
          value="sidebar"
          [(ngModel)]="app.menuMode"
          inputId="layoutMode5"
        ></p-radioButton>
        <label for="layoutMode5">Sidebar</label>
      </div>

      <h5>Color Scheme</h5>
      <div class="field-radiobutton">
        <p-radioButton
          name="darkMode"
          value="light"
          [(ngModel)]="app.colorScheme"
          inputId="darkMode1"
          (onClick)="changeColorScheme('light')"
        ></p-radioButton>
        <label for="darkMode1">Light</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="darkMode"
          value="dark"
          [(ngModel)]="app.colorScheme"
          inputId="darkMode2"
          (onClick)="changeColorScheme('dark')"
        ></p-radioButton>
        <label for="darkMode2">Dark</label>
      </div>

      <h5>Ripple Effect</h5>
      <p-inputSwitch
        [inputId]="'rippleEffect'"
        [ngModel]="app.ripple"
        (onChange)="appMain.onRippleChange($event)"
      ></p-inputSwitch>

      <!-- <h5>Layouts</h5>
          <div class="layout-themes">
            <div *ngFor="let l of layoutColors">
              <a style="cursor: pointer" (click)="changeLayout(l.name)" [ngStyle]="{'background-color': l.color}">
                <i *ngIf="app.layout === l.name" class="pi pi-check"></i>
              </a>
            </div>
          </div> -->

      <h5>Colors</h5>
      <div class="layout-themes">
        @for (t of themeColors; track t) {
          <div>
            <a
              style="cursor: pointer"
              (click)="changeLayout(t.name); changeTheme(t.name)"
              [ngStyle]="{ 'background-color': t.color }"
            >
              @if (app.theme === t.name) {
                <i class="pi pi-check"></i>
              }
            </a>
          </div>
        }
      </div>
    </div>
  `,
})
export class AppConfigComponent implements OnInit {
  layoutColors: any[];

  themeColors: any[];

  config: AppConfig;

  subscription: Subscription;

  constructor(
    public appMain: AppMainComponent,
    public app: AppComponent,
    public configService: ConfigService,
    private titleService: Title,
    private metaService: Meta,
    private staticValuesDataService: StaticValuesDataService,
    public localeService: LocaleService
  ) {
    // const linkElement = document.getElementById('favicon-img');
    // linkElement.setAttribute( 'href', this.staticValuesDataService.getStaticLink(StaticValuesCodes.FAVICON));
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.FAVICON).subscribe(res=>{
    //     linkElement.setAttribute( 'href', res);
    // })
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.TENANT_TITLE).subscribe(res=>{
    //     this.titleService.setTitle(res);
    //     this.configService.tenantTitle = res;
    // })
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.TENANT_DESCRIPTION).subscribe(res=>{
    //     this.metaService.updateTag({name:'description',content:res});
    //     this.configService.tenantDescription = res;
    // })
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.TENANT_OG_IMAGE).subscribe(res=>{
    //     this.metaService.updateTag({property:'og:image',content:res});
    //     this.configService.tenantDescription = res;
    // })
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.FOOTER_SLOGAN).subscribe(res=>{
    //     this.configService.footerSlogan = res;
    // });

    this.staticValuesDataService.getValueByCode(StaticValuesCodes.ICON_LIGHT).subscribe((res) => {
      this.configService.logoImgLight = res;
    });
    this.staticValuesDataService.getValueByCode(StaticValuesCodes.ICON_DARK).subscribe((res) => {
      this.configService.logoImgDark = res;
    });
    this.staticValuesDataService.getValueByCode(StaticValuesCodes.LOGO_LIGHT).subscribe((res) => {
      this.configService.appNameImgLight = res;
    });
    this.staticValuesDataService.getValueByCode(StaticValuesCodes.LOGO_DARK).subscribe((res) => {
      this.configService.appNameImgDark = res;
    });
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.THEME_DEFAULT_COLOR_MODE).subscribe(res=>{
    //     this.configService.appNameImgDark = res;
    // })
    // this.staticValuesDataService.getValueByCode(StaticValuesCodes.DEFAULT_THEME).subscribe(res=>{
    //     this.configService.appNameImgDark = res;
    // })
  }

  ngOnInit() {
    this.localeService.languageState.subscribe((lang) => {
      document.body.dir = lang?.direction?.toLowerCase() || 'ltr';
    });
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });

    this.themeColors = [
      { name: 'blue', color: '#1E88E5' },
      { name: 'green', color: '#43A047' },
      { name: 'magenta', color: '#D81B60' },
      { name: 'orange', color: '#FB8C00' },
      { name: 'purple', color: '#3949AB' },
      { name: 'red', color: '#E53935' },
      { name: 'teal', color: '#00ACC1' },
      { name: 'yellow', color: '#FDD835' },
    ];

    this.layoutColors = [
      { name: 'blue', color: '#1E88E5' },
      { name: 'green', color: '#43A047' },
      { name: 'magenta', color: '#D81B60' },
      { name: 'orange', color: '#FB8C00' },
      { name: 'purple', color: '#3949AB' },
      { name: 'red', color: '#E53935' },
      { name: 'teal', color: '#00ACC1' },
      { name: 'yellow', color: '#FDD835' },
    ];
    // this.changeColorScheme(this.app.colorScheme);
    this.changeLayout(this.app.layout);
    this.changeTheme(this.app.theme);
    this.changeStyleSheetsColor('kendo-css', 'default-main-' + this.app.colorScheme + '.css', 1);
  }

  changeColorScheme(scheme) {
    this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
    this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
    this.changeStyleSheetsColor('kendo-css', 'default-main-' + scheme + '.css', 1);

    this.app.colorScheme = scheme;
    this.configService.updateConfig({ ...this.config, ...{ dark: scheme === 'dark' } });
  }

  changeStyleSheetsColor(id, value, from) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');

    if (from === 1) {
      // which function invoked this function - change scheme
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function - change color
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  changeTheme(theme) {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    const href = 'assets/theme/' + theme + '/theme-' + this.app.colorScheme + '.css';
    this.app.theme = theme;

    this.replaceLink(themeLink, href);
  }

  changeLayout(layout) {
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    const href = 'assets/layout/css/' + layout + '/layout-' + this.app.colorScheme + '.css';
    this.app.layout = layout;

    this.replaceLink(layoutLink, href);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  onConfigButtonClick(event) {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }
}
