@if (loggedInUser$ | async; as user) {
  @if (!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())) {
    <div [ngClass]="{ 'layout-inline-menu': true, 'layout-inline-menu-active': appMain.activeInlineProfile }">
      <a class="layout-inline-menu-action" (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <img src="assets/layout/images/profile-image.png" alt="avatar" style="width: 44px; height: 44px" />
        <span class="layout-inline-menu-text">{{ user.preferred_username }}</span>
        <i class="layout-inline-menu-icon pi pi-angle-down"></i>
      </a>
      <ul
        class="layout-inline-menu-action-panel"
        [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'"
      >
        <li class="layout-inline-menu-action-item">
          <a (click)="appMain.goToProfile()">
            <i class="pi pi-user pi-fw"></i>
            <span>{{ 'general.sideBar.profile' | translate }}</span>
          </a>
        </li>
        <li class="layout-inline-menu-action-item">
          <a (click)="appMain.goToPreferences()">
            <i class="pi pi-user-edit pi-fw"></i>
            <span>{{ 'general.sideBar.preferences' | translate }}</span>
          </a>
        </li>
        <li class="layout-inline-menu-action-item">
          <a (click)="onConfigButtonClick($event)">
            <i class="pi pi-cog pi-fw"></i>
            <span>{{ 'general.sideBar.themeConfig' | translate }}</span>
          </a>
        </li>
        <li class="layout-inline-menu-action-item">
          <a (click)="signOut()">
            <i class="pi pi-power-off pi-fw"></i>
            <span>{{ 'general.sideBar.logout' | translate }}</span>
          </a>
        </li>
        <!-- <li class="layout-inline-menu-action-item">
        <a>
          <i class="pi pi-cog pi-fw"></i>
          <span>Settings</span>
        </a>
      </li>
      <li class="layout-inline-menu-action-item">
        <a>
          <i class="pi pi-user pi-fw"></i>
          <span>Profile</span>
        </a>
      </li> -->
      </ul>
    </div>
  }
}
